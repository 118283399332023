// pages
import Home from "./pages/Home";
import Services from "./pages/Services";
import Products from "./pages/Products";
import Code from "./pages/Code";

// other
import { FC } from "react";

// interface
interface Route {
  key: string;
  title: string;
  path: string;
  enabled: boolean;
  component: FC<{}>;
}

export const routes: Array<Route> = [
  {
    key: "home-route",
    title: "home",
    path: "/",
    enabled: true,
    component: Home,
  },
  {
    key: "services-route",
    title: "services",
    path: "services",
    enabled: true,
    component: Services,
  },
  {
    key: "products-route",
    title: "products",
    path: "products",
    enabled: true,
    component: Products,
  },
  {
    key: "code-route",
    title: "code",
    path: "code",
    enabled: true,
    component: Code,
  },
  {
    key: "about-route",
    title: "about_us",
    path: "about_us",
    enabled: true,
    component: Home,
  },
  {
    key: "contact-us-route",
    title: "contact_us",
    path: "contact_us",
    enabled: true,
    component: Home,
  },
];

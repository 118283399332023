import React, { FC, ReactElement } from "react";

import {
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { routes } from "../routes";
import { HashLink } from "react-router-hash-link";

import { useTranslation } from "react-i18next";

const Navbar: FC = (): ReactElement => {
  const { t } = useTranslation();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const pathname =
    window.location.pathname === "/" || window.location.pathname.includes("#")
      ? "/"
      : decodeURI(window.location.pathname).replace("/", "");

  const navMenu = t("nav_menu", { returnObjects: true }) as {};

  let pageName = "";
  let pageIndex = 0;

  (Object.keys(navMenu) as (keyof typeof navMenu)[]).forEach((key, index) => {
    const label: String = navMenu[key]["label"];

    pageName = label.toLowerCase().replaceAll("/#", "").replaceAll(" ", "-");
    if (pathname === pageName) {
      pageIndex = navMenu[key]["index"];
    }
  });

  const [selectedPageIndex, setSelectedPageIndex] = React.useState(pageIndex);

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleSelectPage = (pageIndex: number) => {
    setSelectedPageIndex(pageIndex);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "secondary.main",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters className="nav-bar">
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "#fff" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {routes.map((page) => {
                const path =
                  page.path === "/"
                    ? page.path
                    : t(`nav_menu.${page.path}.label`)
                        .toLowerCase()
                        .replaceAll(" ", "-");

                return (
                  <HashLink
                    key={page.key}
                    smooth
                    to={path}
                    className="nav-link-mobile"
                  >
                    <MenuItem
                      onClick={handleCloseNavMenu}
                      sx={{
                        color: "#fff",
                        backgroundColor: "#192024",
                      }}
                    >
                      <Typography textAlign="center">
                        {t(`nav_menu.${page.title}.label`).replace("/#", "")}
                      </Typography>
                    </MenuItem>
                  </HashLink>
                );
              })}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {routes.map((page) => {
                let pageIndex = 0;

                if (page.path !== "/") {
                  pageIndex = parseInt(t(`nav_menu.${page.path}.index`), 10);
                }

                const path =
                  page.path === "/"
                    ? "/"
                    : t(`nav_menu.${page.path}.label`)
                        .toLowerCase()
                        .replaceAll(" ", "-");

                return (
                  <HashLink
                    key={page.key}
                    className={
                      selectedPageIndex === pageIndex &&
                      !["about_us", "contact_us"].includes(page.path)
                        ? "nav-link-active"
                        : "nav-link"
                    }
                    smooth
                    to={path}
                    onClick={() => {
                      handleSelectPage(pageIndex);

                      if (path === "/") {
                        document.title = "KDRM";
                      } else {
                        document.title = `KDRM | ${path
                          .replace("/#", "")
                          .toUpperCase()}`;
                      }
                    }}
                  >
                    {t(`nav_menu.${page.title}.label`).replace("/#", "")}
                  </HashLink>
                );
              })}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </Box>
  );
};

export default Navbar;

import React, { ReactElement, FC } from "react";
import i18next from "i18next";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const lang = localStorage.getItem("i18nextLng")?.substring(0, 2) || "en";

const languages: {
  isoCode: string;
  label: string;
  direction: string;
  flag: string;
}[] = [
  {
    isoCode: "en",
    label: "English",
    direction: "ltr",
    flag: "content/flags/gb.svg",
  },
  {
    isoCode: "es",
    label: "Español",
    direction: "ltr",
    flag: "content/flags/es.svg",
  },
];

const LanguageSelect: FC<any> = (): ReactElement => {
  const [language, setLanguage] = React.useState(lang);

  const handleChange = (event: SelectChangeEvent) => {
    if (window.location.hash) {
      window.location.href = "/";
    }

    const isoCode = event.target.value;

    setLanguage(isoCode);
    i18next.changeLanguage(isoCode);
  };

  React.useEffect(() => {
    const direction = languages.filter((lang) => {
      return lang.isoCode === language;
    })[0].direction;

    document.body.dir = direction;
  }, [language]);

  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
          id="selectLanguage"
          value={language}
          onChange={handleChange}
          label="Language"
        >
          {languages.map((language) => {
            return (
              <MenuItem key={language.isoCode} value={language.isoCode}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    className="country-flag"
                    src={language.flag}
                    alt={`flag for country ${language.label}`}
                  ></img>
                  {language.label}
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default LanguageSelect;

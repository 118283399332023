import React, { ReactElement, FC } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const Services: FC<any> = (): ReactElement => {
  const { t } = useTranslation();
  const pageTitle = t("our_services.page_title");
  const services = t("our_services.services", { returnObjects: true }) as [];

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "whitesmoke",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "2em 1em 1em 1em",
      }}
    >
      <div className="page-title">{pageTitle}</div>
      {services.map((service: any, i) => (
        <div key={`service-${i}`} className="services-row">
          <div className="services-image">
            <div className="services-img">
              <img src={service.image_url} alt={service.title} />
            </div>
          </div>
          <div className="services-description">
            <div className="services-title">{service.title}</div>
            <div className="services-body">{parse(service.description)}</div>
          </div>
        </div>
      ))}
    </Box>
  );
};

export default Services;

import React, { FC, ReactElement } from "react";
import LanguageSelect from "./LanguageSelect";

export const Header: FC = (): ReactElement => {
  return (
    <div className="header">
      <div className="header-company-info">
        <div className="header-logo"></div>
        <div className="header-title">KDRM</div>
      </div>
      <div className="header-language-select">
        <LanguageSelect />
      </div>
    </div>
  );
};

export default Header;

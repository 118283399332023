import React, { FC, ReactElement } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";

export const Pagination: FC<{
  noOfPages: number;
  pageIndex: number;
  itemsPerPage: number;
  goToPreviousPage: Function;
  goToPage: Function;
  goToNextPage: Function;
}> = ({
  noOfPages,
  pageIndex,
  itemsPerPage,
  goToPreviousPage,
  goToPage,
  goToNextPage,
}): ReactElement => {
  const page1 = 1;

  const page2 = pageIndex <= 3 || noOfPages / itemsPerPage <= 7 ? 2 : "...";

  const page3 =
    pageIndex <= 3 || noOfPages / itemsPerPage <= 6
      ? 3
      : Math.min(pageIndex, Math.ceil(noOfPages / itemsPerPage - 4));

  const page4 = page3 + 1;
  const page5 = page3 + 2;
  const page6 =
    pageIndex > noOfPages / itemsPerPage - 5 ||
    Math.ceil(noOfPages / itemsPerPage) === 7
      ? Math.ceil(noOfPages / itemsPerPage - 1)
      : "...";
  const page7 = Math.ceil(noOfPages / itemsPerPage);

  return (
    <div
      className="article-paging"
      style={{
        display: !noOfPages || noOfPages <= itemsPerPage ? "none" : "flex",
      }}
    >
      <IconButton aria-label="previousPage" onClick={() => goToPreviousPage()}>
        <ChevronLeftIcon />
      </IconButton>
      <div className="pagination-mobile">
        {pageIndex + 1} of {noOfPages}
      </div>
      <div className="pagination-pages">
        <IconButton
          aria-label="1"
          size="small"
          style={{
            display: noOfPages > itemsPerPage ? "flex" : "none",
          }}
          onClick={() => goToPage(page1)}
        >
          <div
            className={
              pageIndex === 0 ? "pagination-text-selected" : "pagination-text"
            }
          >
            {page1}
          </div>
        </IconButton>
        <IconButton
          aria-label="2"
          size="small"
          style={{
            display: noOfPages > itemsPerPage * 2 ? "flex" : "none",
          }}
          onClick={() => goToPage(page2)}
          disabled={page2 === "..."}
        >
          <div
            className={
              pageIndex === 1 ? "pagination-text-selected" : "pagination-text"
            }
          >
            {page2}
          </div>
        </IconButton>
        <IconButton
          size="small"
          style={{
            display: noOfPages > itemsPerPage * 3 ? "flex" : "none",
          }}
          onClick={() => goToPage(page3)}
        >
          <div
            className={
              pageIndex === 2 ? "pagination-text-selected" : "pagination-text"
            }
          >
            {page3}
          </div>
        </IconButton>
        <IconButton
          size="small"
          style={{
            display: noOfPages > itemsPerPage * 4 ? "flex" : "none",
          }}
          onClick={() => goToPage(page4)}
        >
          <div
            className={
              pageIndex === page4 - 1
                ? "pagination-text-selected"
                : "pagination-text"
            }
          >
            {page4}
          </div>
        </IconButton>
        <IconButton
          size="small"
          style={{
            display: noOfPages > itemsPerPage * 5 ? "flex" : "none",
          }}
          onClick={() => goToPage(page5)}
        >
          <div
            className={
              pageIndex === page5 - 1
                ? "pagination-text-selected"
                : "pagination-text"
            }
          >
            {page5}
          </div>
        </IconButton>
        <IconButton
          size="small"
          style={{
            display: noOfPages > itemsPerPage * 6 ? "flex" : "none",
          }}
          onClick={() => goToPage(page6)}
          disabled={page6 === "..."}
        >
          <div
            className={
              pageIndex === page5
                ? "pagination-text-selected"
                : "pagination-text"
            }
          >
            {page6}
          </div>
        </IconButton>
        <IconButton size="small" onClick={() => goToPage(page7)}>
          <div
            className={
              pageIndex === Math.ceil(noOfPages / itemsPerPage - 1)
                ? "pagination-text-selected"
                : "pagination-text"
            }
          >
            {page7}
          </div>
        </IconButton>
      </div>
      <IconButton onClick={() => goToNextPage()}>
        <ChevronRightIcon />
      </IconButton>
    </div>
  );
};

export default Pagination;

import React, { FC, ReactElement } from "react";
import Carousel from "react-material-ui-carousel";

export const Carousel1: FC = (): ReactElement => {
  const images = [
    {
      src: "cedar-creek.jpg",
    },
    { src: "segovia.jpg" },
  ];

  return (
    <Carousel>
      {images.map((image, index) => (
        <div
          key={index}
          className="carousel-item"
          style={{ backgroundImage: `url(assets/${image.src})` }}
        />
      ))}
    </Carousel>
  );
};

export default Carousel1;

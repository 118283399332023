import React, { FC, ReactElement } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const NotFound: FC = (): ReactElement => {
  const pathname =
    window.location.pathname === "/" || window.location.pathname.includes("#")
      ? "/"
      : decodeURI(window.location.pathname).replace("/", "");

  const pages: { [key: string]: string } = {
    inicio: "home",
    servicios: "services",
    productos: "products",
    código: "code",
    "sobre-nosotros": "about-us",
    "contact-con-nosotros": "contact-us",
    home: "home",
    services: "services",
    products: "products",
    code: "code",
    "about-us": "about-us",
    "contact-us": "contact-us",
  };

  const { t } = useTranslation();

  if (pages[pathname]) {
    const page = t(`nav_menu.${pages[pathname]}.label`)
      .replaceAll(" ", "-")
      .toLowerCase();

    window.location.href = `/${page}`;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "whitesmoke",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "2em 1em 2em 1em",
      }}
    >
      {pages[pathname] ? (
        <div style={{ minHeight: "100VH" }}></div>
      ) : (
        <div className="error-404-page">
          <div className="error-404-background">404</div>
          <div className="error-404-message">{t("errors.not_found")}</div>
        </div>
      )}
    </Box>
  );
};

export default NotFound;

import React, { ReactElement, FC } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { useTranslation } from "react-i18next";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import parse from "html-react-parser";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "../components/Pagination";

const Code: FC<any> = (): ReactElement => {
  const { t } = useTranslation();
  const pageTitle = t("code.page_title");
  const articles = t("code.articles", { returnObjects: true }) as [];
  const articlesPerPage = 1;

  const [pageIndex, setPageIndex] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredArticles = !searchTerm.length
    ? articles
    : articles.filter((article: any) => {
        return article.title.toLowerCase().includes(searchTerm.toLowerCase());
      });

  const goToPreviousPage = () => setPageIndex(Math.max(pageIndex - 1, 0));
  const goToNextPage = () => {
    const idx = Math.min(
      pageIndex + 1,
      Math.ceil(filteredArticles.length / articlesPerPage) - 1
    );

    setPageIndex(idx);
  };

  const goToPage = (index: any) => {
    const idx = parseInt(index, 10) - 1;
    setPageIndex(idx);
  };

  const updateSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageIndex(0);
    setSearchTerm(event.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "whitesmoke",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "2em 1em 1em 1em",
      }}
    >
      <div className="page-title">{pageTitle}</div>
      <div className="page-content">
        <div>{parse(t("code.description"))}</div>
        <hr
          style={{
            margin: "1em 0",
          }}
        />
        <div style={{ display: "flex", justifyContent: "end" }}>
          <FormControl
            variant="standard"
            sx={{ width: "15em", display: "none" }}
          >
            <InputLabel htmlFor="search-for-article">
              Search for articles
            </InputLabel>
            <Input
              id="search-for-article"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment
                  position="end"
                  style={{ display: searchTerm.length ? "flex" : "none" }}
                >
                  <IconButton onClick={clearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
              value={searchTerm}
              onChange={updateSearchTerm}
            />
          </FormControl>
        </div>
        {filteredArticles
          .filter(
            (article: any, i) =>
              i < articlesPerPage * (pageIndex + 1) &&
              i >= articlesPerPage * pageIndex
          )
          .map((article: any, i) => (
            <div key={`article-${i}`}>
              <div className="article-title">{parse(article.title)}</div>
              <div className="article-description">
                {parse(article.description)}
              </div>
              <SyntaxHighlighter
                language="python"
                style={vscDarkPlus}
                wrapLines={true}
                showLineNumbers={true}
                className="article-code"
              >
                {article.code}
              </SyntaxHighlighter>
            </div>
          ))}
        <Pagination
          noOfPages={filteredArticles.length}
          pageIndex={pageIndex}
          itemsPerPage={articlesPerPage}
          goToPreviousPage={goToPreviousPage}
          goToPage={goToPage}
          goToNextPage={goToNextPage}
        />
      </div>
    </Box>
  );
};

export default Code;

import React, { ReactElement, FC, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import Carousel from "../components/Carousel";
import {
  Box,
  Button,
  createTheme,
  Modal,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      light: "#67829e",
      main: "#3a5670",
      dark: "#0b2d45",
      contrastText: "#fff",
    },
  },
});

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const Home: FC<any> = (): ReactElement => {
  const { t } = useTranslation();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const sendEmail = () => {
    const name = document.getElementById("name") as HTMLInputElement;
    const email = document.getElementById("email") as HTMLInputElement;
    const subject = document.getElementById("subject") as HTMLInputElement;
    const message = document.getElementById("message") as HTMLInputElement;
    const captcha = captchaRef.current ? captchaRef.current.getValue() : "-";
    interface EmailData {
      name: string;
      email: string;
      subject: string;
      message: string;
      captcha: string;
    }

    const data: EmailData = {
      name: name ? name.value : "",
      email: email ? email.value : "",
      subject: subject ? subject.value : "",
      message: message ? message.value : "",
      captcha: captcha || "-",
    };

    setOpen(true);

    fetch(
      "https://kb906keqjj.execute-api.ap-southeast-2.amazonaws.com/v1/contact-us",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  React.useEffect(() => {
    setTimeout(() => {
      const aboutUsId = t("nav_menu.about_us.label")
        .replace("/#", "")
        .replaceAll(" ", "-")
        .toLowerCase();

      const aboutUsAnchor = document.getElementById(
        "about-us-anchor"
      ) as HTMLElement;
      aboutUsAnchor.innerHTML = `<div id="${aboutUsId}"></div>`;

      const contactUsId = t("nav_menu.contact_us.label")
        .replace("/#", "")
        .replaceAll(" ", "-")
        .toLowerCase();

      const contactUsAnchor = document.getElementById(
        "contact-us-anchor"
      ) as HTMLElement;
      contactUsAnchor.innerHTML = `<div id="${contactUsId}"></div>`;

      if (window.location.hash) {
        const path = `${window.location.hash
          .replace("#", "")
          .replaceAll(" ", "-")
          .toLowerCase()}`;

        const id =
          contactUsId === path ? "contact-us-anchor" : "about-us-anchor";

        const elem = document.getElementById(id) as HTMLElement;
        elem.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }
    }, 100);
  });

  return (
    <div>
      <Carousel />
      <div
        className="page-content"
        style={{ textAlign: "center", marginTop: "2em" }}
      >
        <div className="section-heading dark">
          {t("home_page.what_we_do_title")}
        </div>
        <div>
          <p>{t("home_page.what_we_do_body")}</p>
        </div>
        <br />
        <div className="section-row">
          <div className="section-card-3">
            <div id="icon-cloud" className="section-card-icon"></div>
            <p>{t("home_page.cloud_services")}</p>
          </div>
          <div className="section-card-3">
            <div id="icon-apps" className="section-card-icon"></div>
            <p>{t("home_page.app_services")}</p>
          </div>
          <div className="section-card-3">
            <div id="icon-database" className="section-card-icon"></div>
            <p>{t("home_page.database_services")}</p>
          </div>
        </div>
      </div>
      <div className="dark-background">
        <div id="our-services" className="page-content">
          <div className="section-heading light">
            {t("home_page.how_we_help_our_clients_title")}
          </div>
          <p>{t("home_page.how_we_help_our_clients_body_paragraph_1")}</p>
          <p>{t("home_page.how_we_help_our_clients_body_paragraph_2")}</p>
          <p>{t("home_page.how_we_help_our_clients_body_paragraph_3")}</p>
          <br />
          <div className="section-row">
            <div className="section-card-2">
              <span className="section-card-2-header">
                {t("home_page.we_can_help_you_discover")}
              </span>
              <div className="zoom-img">
                <img src="assets/discovery.jpg" alt="discovery.jpg" />
              </div>
            </div>
            <div className="section-card-2">
              <span className="section-card-2-header">
                {t("home_page.we_can_help_you_build")}
              </span>
              <div className="zoom-img">
                <img src="assets/build.jpg" alt="build.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="light-background">
        <div className="page-content">
          <div className="section-row">
            <div id="about-us-anchor"></div>
            <div className="footer-card-2">
              <div className="footer-card-title">
                {t("home_page.about_our_company_title")}
              </div>
              <p>{t("home_page.about_our_company_body_paragraph_1")}</p>
              <p>{t("home_page.about_our_company_body_paragraph_2")}</p>
              <p>{t("home_page.about_our_company_body_paragraph_3")}</p>
            </div>
            <div className="footer-card-2">
              <div className="footer-card-title">
                {t("home_page.contact_us")}
              </div>
              <form autoComplete="off">
                <ThemeProvider theme={theme}>
                  <TextField
                    id="name"
                    label={t("home_page.contact_us_your_name")}
                    required
                    fullWidth={true}
                    sx={{ margin: "0.5em 0 0.5em 0", backgroundColor: "white" }}
                  />
                  <TextField
                    id="email"
                    label={t("home_page.contact_us_your_email")}
                    required
                    fullWidth={true}
                    sx={{ margin: "0.5em 0 0.5em 0", backgroundColor: "white" }}
                  />
                  <TextField
                    id="subject"
                    label={t("home_page.contact_us_subject")}
                    required
                    fullWidth={true}
                    sx={{ margin: "0.5em 0 0.5em 0", backgroundColor: "white" }}
                  />
                  <TextField
                    id="message"
                    aria-label="message"
                    label={t("home_page.contact_us_your_message")}
                    fullWidth={true}
                    multiline={true}
                    rows={5}
                    sx={{ margin: "0.5em 0 0.5em 0", backgroundColor: "white" }}
                  />
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <ReCAPTCHA
                      ref={captchaRef}
                      sitekey="6LdIttkjAAAAALMCXAcY_cNGvBJaKy1dkl4AsGpP"
                    />
                  </div>
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        sendEmail();
                      }}
                      sx={{ margin: "2em 0 0.5em 0" }}
                    >
                      {t("home_page.submit_button")}
                    </Button>
                  </div>

                  <Modal
                    open={open}
                    onClose={handleClose}
                    onKeyDown={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={modalStyle}>
                      <Typography id="modal-modal-description">
                        {t("home_page.thank_you_for_your_message")}
                      </Typography>
                    </Box>
                  </Modal>
                </ThemeProvider>
              </form>
            </div>
            <div id="contact-us-anchor"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

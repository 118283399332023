import React from "react";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes as appRoutes } from "./routes";
import NotFound from "./pages/NotFound";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import { useTranslation } from "react-i18next";

import "./App.css";

function App() {
  const { t } = useTranslation();

  const pathname =
    window.location.pathname === "/" || window.location.pathname.includes("#")
      ? "/"
      : decodeURI(window.location.pathname).replace("/", "");

  if (pathname !== "/") {
    document.title = `KDRM | ${pathname.replace("/#", "").toUpperCase()}`;
  }

  const theme = createTheme({
    palette: {
      primary: {
        light: "#63b8ff",
        main: "#0989e3",
        dark: "#005db0",
        contrastText: "#FFF",
      },
      secondary: {
        main: "#192024",
        light: "#82e9de",
        dark: "#00867d",
        contrastText: "#FFF",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box height="100vh" display="flex" flexDirection="column">
        <Router>
          <Header />
          <Navbar />
          <Routes>
            {appRoutes.map((route) => {
              const path =
                route.path === "/"
                  ? route.path
                  : t(`nav_menu.${route.path}.label`)
                      .toLowerCase()
                      .replaceAll(" ", "-");

              return (
                <Route
                  key={route.key}
                  path={path}
                  element={<route.component />}
                />
              );
            })}
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;

import React, { ReactElement, FC } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { useTranslation } from "react-i18next";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import parse from "html-react-parser";
import { Box } from "@mui/material";
import Pagination from "../components/Pagination";

const Products: FC<any> = (): ReactElement => {
  const { t } = useTranslation();
  const pageTitle = t("products.page_title");
  const products = t("products.products", { returnObjects: true }) as [];
  const productsPerPage = 1;

  const [pageIndex, setPageIndex] = React.useState(0);

  const goToPreviousPage = () => setPageIndex(Math.max(pageIndex - 1, 0));
  const goToNextPage = () => {
    const idx = Math.min(
      pageIndex + 1,
      Math.ceil(products.length / productsPerPage) - 1
    );

    setPageIndex(idx);
  };

  const goToPage = (index: any) => {
    const idx = parseInt(index, 10) - 1;
    setPageIndex(idx);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "whitesmoke",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "2em 1em 1em 1em",
      }}
    >
      <div className="page-title">{pageTitle}</div>
      <div className="page-content">
        <div>{parse(t("products.description"))}</div>
        <hr
          style={{
            margin: "1em 0 2em 0",
          }}
        />
        <div>
          {products
            .filter(
              (product: any, i) =>
                i < productsPerPage * (pageIndex + 1) &&
                i >= productsPerPage * pageIndex
            )
            .map((product: any, i) => (
              <div key={`products-${i}`}>
                <div className="article-title">{product.title}</div>
                <div className="article-description">
                  {parse(product.description)}
                </div>
                <div>
                  <img
                    src={product.image}
                    alt={product.image_alt_text}
                    className="article-img"
                  />
                </div>
                {product.code && product.language ? (
                  <div style={{ height: 500, display: "flex" }}>
                    <SyntaxHighlighter
                      language={product.language}
                      style={vscDarkPlus}
                      wrapLines={true}
                      showLineNumbers={true}
                      className="article-code"
                    >
                      {product.code}
                    </SyntaxHighlighter>
                  </div>
                ) : null}
              </div>
            ))}
        </div>
        <Pagination
          noOfPages={products.length}
          pageIndex={pageIndex}
          itemsPerPage={productsPerPage}
          goToPreviousPage={goToPreviousPage}
          goToPage={goToPage}
          goToNextPage={goToNextPage}
        />
      </div>
    </Box>
  );
};

export default Products;
